import axios from 'axios';

import { Heading, Pane } from 'evergreen-ui'

import styles from "./css/Survey.module.css";
import { useEffect, useState } from 'react';
import { BASE_URL } from '../api/axios';


export default function SurveyFinishSuccess ({ hash, responseSurvey }) {

	useEffect(() => {		

/*
		let newSurvey = await connection.query(queryes.SQLInsertSurvey, [
			clave,
      claveVisita,
			hash,
			body.respuesta_01,
			body.respuesta_02,
			body.respuesta_03,
			body.respuesta_04,
			body.respuesta_05,
			body.respuesta_06,
			body.respuesta_07,
			body.respuesta_08,
			body.respuesta_08_otro,
			body.respuesta_09,
			body.option
		]);	

*/

		const doExecute = async () => {
			try {
				//console.log(responseSurvey);

				const bodyRequest = {
					hash,
          respuesta_01 : responseSurvey[0].response,
					respuesta_02 : responseSurvey[1].response,
					respuesta_03 : responseSurvey[2].response,
					respuesta_04 : responseSurvey[3].response,
					respuesta_05 : responseSurvey[4].response,
					respuesta_06 : responseSurvey[5].response,
					respuesta_07 : responseSurvey[6].response,
					respuesta_08 : responseSurvey[7].response,
					respuesta_08_otro : '',
					respuesta_09 : responseSurvey[8].response
				};

				//console.log(bodyRequest);
				//console.log("request survey")
				const response = await axios.post(BASE_URL + '/survey',
				bodyRequest,
						{
								headers: {'Content-Type':'application/json', 'Access-Control-Allow-Origin' : '*'},
								withCredentials: true,
						}
				); 				

				setTimeout(() => {
					window.open("https://unilabor.mx", "_parent")
				}, 1000)
						

			} catch (err) {
				if (!err?.response) {
					console.log(err.response);
				}
			}
		};

		doExecute();
	}, []);

	const [isShown, setIsShown] = useState(false);

	useEffect(() => {
	
		setTimeout(() => {
			setIsShown(true)
		}, 1000);
	
	}, [])
	
 
  return (
			<>
    	<Pane className={styles.surveyAnimation}					
					width="100%"
					height="100%"
					margin={20}					
					display="flex"
					padding={30}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"				
					>		
					<Heading size={600}>¡Valoramos tu opinión!</Heading>	
					<Heading size={800} marginTop={20}>¡Gracias por usar los servicios UNILABOR!</Heading>					

					
			</Pane>   
			
			{/* <CornerDialog
        title="UNILABOR premia tu participación"
        isShown={isShown}
				hasCancel={false}
				hasFooter={false}
      >
				<Paragraph marginTop={20}>
	        <Text>En agradecimiento por participación en la encuesta electrónica.</Text>
				</Paragraph>				
				<Paragraph marginTop={5}>
	        <Text>Te ofrecemos completamente gratis en tu próxima visita el estudio de <label className={styles.colorGray}>Glucosa.</label></Text>
				</Paragraph>

				<Paragraph>
					<Avatar
  					src="https://img.freepik.com/vector-premium/diseno-icono-vector-logotipo-caja-regalo_598213-3309.jpg?w=1380"
  					name="Especialista"
  					size={70}
					/>
				</Paragraph>
      </CornerDialog>    */}
			</>
		    
  )
}
