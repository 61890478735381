
import styles from "./css/ExpedienteCard.module.css";


export default function NoDisponible() {

  return (
    <>
      <div className={styles.contentLeyenda}>
        <p className={styles.contentLeyendaText}>Sus estudios no están publicados.</p>        
      </div>
    </>
  )
}