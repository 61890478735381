
import styles from "./css/ExpedienteCard.module.css";


import LeyendaInstitucion from "./LeyendaInstitucion";
import NoDisponible from "./NoDisponible";
import ViewPDFTab from "./ViewPDFTab";
//import ButtonGroup from "./ButtonGroup";

export function ExpedienteCard({ expediente }) {
	
	return (
		<>
		<div className={styles.expedienteCardTitle}>
			expediente digital
		</div>

		<div className={styles.expedienteCard}>				
				<div className={styles.cardHeader}>			
					<div className={styles.title}>
						FOLIO <strong>{expediente.folio_paciente}</strong>
					</div>				
					<div className={styles.title}>
						PACIENTE <strong>{expediente.nombre_completo}</strong>
					</div>
					<div className={styles.title}>
						FECHA NACIMIENTO <strong>{expediente.fecha_nacimiento + ' - ' + expediente.edad }</strong>
					</div>
					<div className={styles.title}>
						ESPECIALISTA <strong>{expediente.medico}</strong>
					</div>    
				</div>				

				{ (expediente.visible_portal === 0) && <LeyendaInstitucion/>
				}	

				{ (expediente?.publicado === 0 && expediente.visible_portal === 1) && <NoDisponible/>
				}		

				{ (expediente?.publicado === 1 && expediente.visible_portal === 1) && <ViewPDFTab expediente = { expediente }>  			
  				</ViewPDFTab>
				}				
			

		</div>
		</>
	);
}