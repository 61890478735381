import React, { useMemo } from 'react'

import { Button, Group, Pane, Text } from 'evergreen-ui'

import styles from "./css/Survey.module.css";
import getQuestion from '../resource/SurveyQuestion';

export default function Survey02 ({ setLevel, responseSurvey, updateResponses }) {
 
  const options = useMemo(
    () => [
      { label: 'Bueno', value: 2 },
      { label: 'Regular', value: 1 },
      { label: 'Malo', value: 0 },
    ],
    []
  )

  const optionsTotal = useMemo(
    () => [
      { label: 'Totalmente de acuerdo', value: 2 },
      { label: 'De acuerdo', value: 1 },
      { label: 'En desacuerdo', value: 0 },
    ],
    []
  )	

  return (
			<>
    	<Pane className={styles.surveyAnimation}					
					width="100%"
					height="100%"
					margin={20}					
					display="flex"
					padding={30}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"					
					>          
					<div>
						<Text marginTop={100}>{getQuestion(4).question}</Text>	
						<Pane display="flex" justifyContent="center">
						<Group marginBottom={50} marginTop={20}>
      					{options.map(({ label, value }) => (
        				<Button key={label} isActive={responseSurvey[3].response === value} onClick={() => updateResponses(4, value)}>
        				  {label}
				        </Button>
      					))}
    					</Group>
						</Pane>
					</div>
					<div>
						<Text marginTop={100}>{getQuestion(5).question}</Text>	
						<Pane display="flex" justifyContent="center">
						<Group marginBottom={50} marginTop={20}>
      					{optionsTotal.map(({ label, value }) => (
        				<Button key={label} isActive={responseSurvey[4].response === value} onClick={() => updateResponses(5, value)}>
        				  {label}
				        </Button>
      					))}
    					</Group>
						</Pane>
					</div>
					<div>
						<Text marginTop={100}>{getQuestion(7).question}</Text>	
						<Pane display="flex" justifyContent="center">
						<Group marginBottom={50} marginTop={20}>
      					{options.map(({ label, value }) => (
        				<Button key={label} isActive={responseSurvey[5].response === value} onClick={() => updateResponses(6, value)}>
        				  {label}
				        </Button>
      					))}
    					</Group>
						</Pane>
					</div>

					<div>
						<Pane display="flex" justifyContent="center">
							<Button className={styles.buttonSurveyCategory} marginTop={16} marginLeft={3} onClick={()=> setLevel(3)}>Continuar</Button>				
						</Pane>
					</div>

			</Pane>    
			</>
		    
  )
}
