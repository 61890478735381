

import React from 'react'

import { Avatar, Pane, Text } from 'evergreen-ui'
import { Link } from 'react-router-dom';

import styles from "./css/ExpedienteCard.module.css";


export default function EspecialidadCard({ especialidad }) {
  const prefijo = './assets/icon-color/'; //'https://unilabor.mx/assets/icon-color/';
  //const prefijo = './assets/icon-one-color/'; //'https://unilabor.mx/assets/icon-color/';
  //console.log(especialidad);
  return (
		<Link to={`/especialidad/${especialidad.cve_especialidad}`}>
    	<Pane className={styles.especialidadCard}
					boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.07)"					
					width={250}
					height={200}
					margin={20}
					borderRadius={10}         
					display="flex"
					padding={30}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					cursor="pointer"					
					>
          <Avatar src={prefijo + especialidad.descripcion_avatar} name={especialidad.especialidad} size={80} />
					<Text paddingTop={20}>{especialidad.especialidad}</Text>					
			</Pane>    
		</Link>
		    
  )
}
