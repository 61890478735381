
import { Button, Heading, Pane, Text } from 'evergreen-ui'

import styles from "./css/Survey.module.css";


export default function SurveyBegin ({ setLevel, survey }) {

	const handleClick = (value) => {
		setLevel(value)
	}

  return (
		<>
    	<Pane className={styles.surveyAnimation}					
					width="100%"
					height="100%"
					margin={20}					
					display="flex"
					padding={30}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"					
					>          
					<Heading size={800} marginTop={20}>{`¡Hola ${survey.nombre}!`}</Heading>					
					<Heading size={400} marginTop={20}>Para nosotros, tu opinión es muy importante</Heading>
					{/* <Heading size={400} marginTop={20} marginBottom={50} >{`Pensando en la visita que realizaste a ${survey.unidad}, el día  ${survey.fecha}, te invitamos a participar en la siguiente encuesta. `}</Heading> */}
					<Text marginTop={20} marginBottom={50} >{` Pensando en la visita que realizaste a ${survey.unidad}, el día  ${survey.fecha}, te invitamos a participar en la siguiente encuesta. `}</Text>
					<div>
						<Pane display="flex" justifyContent="center">
							<Button className={styles.buttonSurveyCategory} marginTop={16} marginLeft={10} onClick={() => handleClick(1)}>Participar</Button>				
							<Button className={styles.buttonSurveyCategory} marginTop={16} marginLeft={10} onClick={() => handleClick(10)}>No participar</Button>				
						</Pane>
					</div>

			</Pane>    
		</>		    
  )
}
