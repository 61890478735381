
// Encuesta de Servicios.
// En UNILABOR, comprometidos con la calidad y calidez en nuestros servicios y en búsqueda 
// de una mejora continua nos interesa conocer tu opinión co la siguiente encuesta:
const Question = [
  {
    number : 1,
    question : "En general, ¿cómo calificas la calidad de nuestro servicio?",
    response : "Bueno = 2, Regular = 1, Malo = 0"
  },
  {
    number : 2,
    question : "¿Cómo calificas la atención brindada por el personal de recepción?",
    response : "Bueno = 2, Regular = 1, Malo = 0"
  },
  {
    number : 3,
    question : "¿Te encuentras satisfecho con la información o indicaciones que te proporcionó la recepcionista, sobre las condiciones en que debes asistir a tu toma de muestra?",
    response : "Si = 1, No = 0"
  },
  {
    number : 4,
    question : "¿Cómo calificas la atención brindada por el personal que tomó tu muestra?",
    response : "Bueno, Regular, Malo"
  },
  {
    number : 5,
    question : "El personal que tomó tu muestra, ¿lo realizó adecuadamente?",
    response : "Totalmente de acuerdo = 2, De acuerdo = 1, En desacuerdo = 0"
  },
  {
    number : 6,
    question : "En general, ¿cómo calificarías la claridad de las instrucciones brindadas al momento de ser atendido en tu toma de muestra?",
    response : "Bueno, Regular, Malo"
  },
  {
    number : 7,
    question : "¿Cómo consideras el tiempo de espera para recibir el servicio?",
    response : "Poco = 2, Adecuado = 1, Mucho = 0"
  },
  {
    number : 8,
    question : "¿Existió alguna situación que causara insatisfacción en tu visita a UNILABOR?",
    response : "Tiempo de espera = 3, Actitud de nuestro personal = 2, Otro = 1, Ninguna = 0"
  },
  {
    number : 9,
    question : "Apórtanos alguna sugerencia para mejorar nuestro servicio",
    response : ""
  }
]

const getQuestion = (number) => {
  return Question.find(item => item.number === number);
}

export default getQuestion;