import React, { useEffect, useState } from "react";

import axios, { BASE_URL } from "./../api/axios";

import { Pane } from "evergreen-ui";

import { Spinner } from "../components/Spinner";
import EspecialistaCard from "../components/EspecialistaCard";
import { useParams } from "react-router-dom";

export default function CatEspecialista() {
	const [loading, setloading] = useState(true);
	const [listado, setlistado] = useState([]);

	const { especialidad } = useParams();	
	useEffect(() => {
		setloading(true);

		const doExecute = async () => {
			try {
				const response = await axios.get(BASE_URL + `/medic/${especialidad}`, {
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					withCredentials: true,
				});

				setlistado(response?.data);
				//console.log(listado);
				setloading(false);
			} catch (err) {
				if (!err?.response) {
					console.log(err.response);
				}
				setlistado([]);
			}
		};

		doExecute();
	}, []);

	if (loading) {
		return <Spinner />;
	}

	return (
		<>
			<Pane display="flex" flexWrap="wrap" justifyContent="center">
				{listado &&
					listado.map((item, idx) => (
						<EspecialistaCard key={idx} especialista={item} />
					))}
			</Pane>
		</>
	);
}
