import React, { useMemo } from 'react'
 
import { Button, Group, Pane, Text, Textarea } from 'evergreen-ui'

import styles from "./css/Survey.module.css";
import getQuestion from '../resource/SurveyQuestion';

export default function Survey03 ({ setLevel, responseSurvey, updateResponses }) {

  const options = useMemo(
    () => [
      { label: 'Poco', value: 2 },
      { label: 'Adecuado', value: 1 },
      { label: 'Mucho', value: 0 },
    ],
    []
  )

  const optionsActitud = useMemo(
    () => [
      { label: 'Tiempo de espera', value: 1 },
      { label: 'Actitud del personal', value: 2 },
      { label: 'Otro', value: 3 },
			{ label: 'Ninguno', value: 4 },
    ],
    []
  )	

	return (
			<>
    	<Pane className={styles.surveyAnimation}					
					width="100%"
					height="100%"
					margin={20}					
					display="flex"
					padding={30}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"					
					>          
					<div>
						<Text marginTop={100}>{getQuestion(7).question}</Text>	
						<Pane display="flex" justifyContent="center">
						<Group marginBottom={50} marginTop={20}>
      					{options.map(({ label, value }) => (
        				<Button key={label} isActive={responseSurvey[6].response === value} onClick={() => updateResponses(7, value)}>
        				  {label}
				        </Button>
      					))}
    					</Group>
						</Pane>
					</div>
					<div>
						<Text marginTop={100}>{getQuestion(8).question}</Text>	
						<Pane display="flex" justifyContent="center">
						<Group marginBottom={50} marginTop={20}>
      					{optionsActitud.map(({ label, value }) => (
        				<Button key={label} isActive={responseSurvey[7].response === value} onClick={() => updateResponses(8, value)}>
        				  {label}
				        </Button>
      					))}
    					</Group>
						</Pane>
					</div>
					<div>
						<Text marginTop={100}>{getQuestion(9).question}</Text>	
						<Pane display="flex" justifyContent="center">
  						<Textarea 
								placeholder='Describe tu sugerencia - máximo 300 letras.'
								maxLength={300}
								onChange={(event) => updateResponses(9, event.target.value)} value={responseSurvey[8].value} />
						</Pane>
					</div>


					<div>
						<Pane display="flex" justifyContent="center">
							<Button className={styles.buttonSurveyCategory} marginTop={16} marginLeft={10} onClick={() => setLevel(4)}>Finalizar</Button>				
						</Pane>
					</div>

			</Pane>  
			</>
		    
  )
}
