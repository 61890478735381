import "./App.css";

import { HashRouter, Route, Routes, } from "react-router-dom";
import Footer from "./components/Footer";

import Navbar from "./components/Navbar";
import { Expediente } from "./pages/Expediente";
import CustomRecaptcha from "./pages/CustomRecaptcha";


import CatEspecialidad from "./pages/CatEspecialidad";
import CatEspecialista from "./pages/CatEspecialista";
import Survey from "./pages/Survey";

function App() {
	return ( 
		<div className="grid-container">
				<HashRouter>
					<Navbar />
					<Routes>
						<Route path="/:uuid" element={<CustomRecaptcha/>}>
						</Route>
						 
						<Route path="/expediente/:hash" element={<Expediente />} />				
						<Route path="/exp/:hash" element={<Expediente />} />				
						<Route path="/exp/:hash/:option" element={<Expediente />} />

						<Route path="/valorame/:hash/:option" element={<Survey />} />		
						<Route path="/valorame/:hash" element={<Survey />} />		
						<Route path="/valorame/:hash/:nombre/:unidad/:fecha" element={<Survey />} />		

						<Route path="/especialidad" element={<CatEspecialidad/>}/>
						<Route path="/especialidad/:especialidad" element={<CatEspecialista/>}/>
					</Routes>
					<Footer /> 
				</HashRouter>
		</div>
	);
}

export default App;
