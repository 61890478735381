import React, { useMemo } from 'react'

import { Button, Group, Pane, Text } from 'evergreen-ui'
import getQuestion from '../resource/SurveyQuestion';
import styles from "./css/Survey.module.css";


export default function Survey01 ({ setLevel, responseSurvey, updateResponses }) {

  const options = useMemo(
    () => [
      { label: 'Bueno', value: 2 },
      { label: 'Regular', value: 1 },
      { label: 'Malo', value: 0 },
    ],
    []
  )

  const optionsYes = useMemo(
    () => [
      { label: 'Si', value: 1 },
      { label: 'No', value: 0 },      
    ],
    []
  )	 

	const handleClick = (value) => {
		setLevel(value)
	}
	
  return (
			<>
    	<Pane className={styles.surveyAnimation}					
					width="100%"
					height="100%"
					margin={20}					
					display="flex"
					padding={30}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"					
					>          
					<div>
						<Text marginTop={100}>{getQuestion(1).question}</Text>	
						<Pane display="flex" justifyContent="center">
						<Group marginBottom={50} marginTop={20}>
      					{options.map(({ label, value }) => (
        				<Button key={label}									
									isActive={responseSurvey[0].response === value} onClick={() => updateResponses(1, value)}>
        				  {label}
				        </Button>
      					))}
    					</Group>
						</Pane>
					</div>
					<div>
						<Text marginTop={100}>{getQuestion(2).question}</Text>	
						<Pane display="flex" justifyContent="center">
						<Group marginBottom={50} marginTop={20}>
      					{options.map(({ label, value }) => (
        				<Button key={label} isActive={responseSurvey[1].response === value} onClick={() => updateResponses(2, value)}>
        				  {label}
				        </Button>
      					))}
    					</Group>
						</Pane>
					</div>
					<div>
						<Text marginTop={100}>{getQuestion(3).question}</Text>	
						<Pane display="flex" justifyContent="center">
						<Group marginBottom={50} marginTop={20}>
      					{optionsYes.map(({ label, value }) => (
        				<Button key={label} isActive={responseSurvey[2].response === value} onClick={() => updateResponses(3, value)}>
        				  {label}
				        </Button>
      					))}
    					</Group>
						</Pane>
					</div>

					<div>
						<Pane display="flex" justifyContent="center">
							<Button className={styles.buttonSurveyCategory} marginTop={16} marginLeft={10} onClick={()=> handleClick(2)}>Continuar</Button>				
						</Pane>
					</div>

			</Pane>    
			</>
		    
  )
}
