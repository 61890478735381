import React from "react";
import "./css/Footer.css";

export default function Footer() {
	const date = new Date();
	const year = date.getFullYear();
	return (
		<> 
			<footer className="footer-content">				
				<div className="footer-social">
					<div className="social-item">
						<a href="https://api.whatsapp.com/send?phone=529933117978&text=Bienvenido a UNILABOR. ¿En qué podemos apoyarle el día de hoy?" target="_blank" rel="noreferrer">
							<i className="fa fa-whatsapp social-icon"></i>
						</a> 
					</div>
					<div className="social-item">
						<a href="https://www.facebook.com/UNILABORmr" target="_blank" rel="noreferrer">
							<i className="fa fa-facebook-f social-icon"></i>
						</a>
					</div>
					<div className="social-item">
						<a href="https://www.unilabor.mx/" target="_blank" rel="noreferrer">
							<i className="fa fa-globe social-icon"></i>
						</a>
					</div>					
				</div>
				<div className="footer-bottom">
					<p>&copy; Derechos Reservados {year} UNILABOR&reg;</p>
				</div>
			</footer>
		</>
	);
}
