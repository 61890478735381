import React from 'react'

import { Avatar, MapMarkerIcon, MobilePhoneIcon, Pane, Paragraph, Text } from 'evergreen-ui'

import styles from "./css/ExpedienteCard.module.css";


export default function EspecialistaCard({ especialista }) {    
	
	const handleMaps = (coordenadas) => {
		window.open('https://www.google.com/maps/search/?api=1&query=hospital+angeles+villahermosa')
	}

  return (
    	<Pane className={styles.especialidadCard}
					boxShadow="0 1px 2px 0 rgba(0, 65, 106, 0.5)"					
					width={500}
					height={250}
					margin={20}
					borderRadius={5}         
					display="flex"
					padding={30}
					justifyContent="center"					
					flexDirection="column"
					>
          <Avatar src={especialista.descripcion_avatar} name={especialista.nombre} size={80} />
					<Text paddingTop={20} fontSize={18}>{especialista.nombre}</Text>					
					<Text paddingBottom={10} fontSize={14} color="gray">{especialista.especialidad}</Text>															
					{especialista.ubicacion && 						
						<Pane className={styles.itemLink} display="flex" flexDirection="row" cursor="pointer" onClick={() => { handleMaps(especialista.utm_coordenadas)}}>						
							<MapMarkerIcon color="#97a3ac" marginRight={10} size={20} />
							<Paragraph paddingTop={0}>{especialista.ubicacion}</Paragraph>
						</Pane>
					}

					<Paragraph paddingTop={10}></Paragraph>
					{especialista.telefono && 

						<Pane className={styles.itemLink} display="flex" flexDirection="row" cursor="pointer">
							<MobilePhoneIcon color="#bfd4e6" marginRight={10} size={20} />						
							<a href={'tel:+52' + especialista.telefono_formato}>{especialista.telefono}</a>
						</Pane>
					}				
			</Pane>		    
  )
}
