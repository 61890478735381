import { useEffect, useState } from "react";
import { ExpedienteCard } from "./../components/ExpedienteCard";
import styles from "./css/ExpedientePage.module.css";
import { Spinner } from "./../components/Spinner";
import { Empty } from "../components/Empty";

//import AlertCustom from "../config/AlertCustom";
//import { TipoMensaje } from "../config/TiposDatos";

import axios, { BASE_URL } from "./../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import { Button, CornerDialog, Heading, Pane, Paragraph, Text, toaster } from "evergreen-ui";

export function 	Expediente() {
	const [expediente, setExpediente] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { hash, option } = useParams();
	const [isShown, setIsShown] = useState(false); 

	//console.log(hash);
	const navigate = useNavigate();
  
	useEffect(() => {
		setIsLoading(true);
		const doExecute = async () => {
			try {
				const response = await axios.get(
					option ? 
						BASE_URL + `/expediente/${hash}/${option}` 
					:	BASE_URL + `/expediente/${hash}`,
					{
						headers: {
							"Content-Type": "application/json",
							"Access-Control-Allow-Origin": "*",
						},
						withCredentials: true,
					}
				);

				setExpediente(response?.data);
				setIsLoading(false);
				console.log(expediente);
			} catch (err) {
				if (!err?.response) {
					console.log(err.response);
				}
					/*
					AlertCustom(TipoMensaje.ERROR, "El servidor web no responde");
				} else if (err.response?.status === 401) {
					AlertCustom(TipoMensaje.ERROR, "No se encontraron coindencias");
				} else if (err.response?.status === 400) {
					AlertCustom(TipoMensaje.ERROR, "Acceso no autorizado");
				} else {
					AlertCustom(TipoMensaje.ERROR, "Fallo general");
				}
				*/
				setExpediente({});
			}
		};

		doExecute();

	}, []);

	useEffect(() => {
	
		setTimeout(() => {
			setIsShown(true)
		}, 7000);
	
	}, [])	

	const doHome = () => {
		setTimeout(() => {
			navigate("/");
		}, 5000)
	}

	const handleSurvey = () => {
		//<Route path="/valorame/:hash/:nombre/:unidad/:fecha" element={<Survey />} />	
		navigate(`/valorame/${expediente.hash_portal}/${expediente.nombre}/${expediente.unidad}/${expediente.fecha}`)
	}

	if (isLoading) {
		return <Spinner />; 
	}

	if (!isLoading && expediente?.length === 0) {
		toaster.danger("Expediente no encontrado");
		doHome();		
		//return <Empty mensaje="Expediente no encontrado" />;
	}

	return (
		<div className={styles.contentMain}>
				{/* <Especialidades/>	 */}
				{expediente &&					
						<ExpedienteCard expediente={ expediente } />
				}			

			<div>
			<CornerDialog
				position="bottom-left"
        isShown={isShown}
				hasCancel={false}
				hasFooter={false}
				width={300}
				className={styles.cornerDialog}
      >
				<Paragraph alignItems="center">
					<Heading>Encuesta de servicio</Heading>
				</Paragraph>	
				<Paragraph marginTop={5}>
	        <Text>Participa y danos tu opinión respecto a los servicios ofrecidos por UNILABOR.</Text>
				</Paragraph>
				<div>
						<Pane display="flex" justifyContent="center">
							<Button className={styles.buttonSurveyCategory} marginTop={16} marginLeft={10} onClick={()=> handleSurvey()}>IR A LA ENCUESTA</Button>				
						</Pane>
					</div>
      </CornerDialog> 	
			</div>			
		</div>
	);
}
