
import { Heading, Pane } from 'evergreen-ui'

import styles from "./css/Survey.module.css";
import { useEffect } from 'react';


export default function SurveyAccessDenied() {

	useEffect(() => {
		setTimeout(() => {
			window.open("https://unilabor.mx", "_parent")
		}, 2000)
	
	}, [])
	

  return (
			<>
    	<Pane className={styles.surveyAnimation}					
					width="100%"
					height="100%"
					margin={20}					
					display="flex"
					padding={30}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"					
					>          
					<Heading size={600} marginTop={20}>Acceso no permitido</Heading>					
					<Heading size={800} marginTop={20}>¡En breve sera redirigido al portal UNILABOR!</Heading>					
					
			</Pane>    
			</>
		    
  )
}
