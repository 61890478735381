import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios, { BASE_URL } from "./../api/axios";

import { Pane } from "evergreen-ui";

import { Spinner } from "../components/Spinner";
import SurveyBegin from "../components/SurveyBegin";
import Survey01 from "../components/Survey01";
import Survey02 from "../components/Survey02";
import Survey03 from "../components/Survey03";
import SurveyEnd from "../components/SurveyEnd";
import SurveyFinishSuccess from "../components/SurveyFinishSucess";
import SurveyAccessDenied from "../components/SurveyAccessDenied";

export default function Survey() {
	const [loading, setLoading] = useState(true);
	const [survey, setSurvey] = useState({});
	const [level, setLevel] = useState(0);

	const initialState = [
		{reactive : 1, response : 2},
		{reactive : 2, response : 2},
		{reactive : 3, response : 1},
		{reactive : 4, response : 2},
		{reactive : 5, response : 2},
		{reactive : 6, response : 2},
		{reactive : 7, response : 3},
		{reactive : 8, response : 4},
		{reactive : 9, response : ''}
	]

	const [responseSurvey, setResponseSurvey] = useState(initialState);

  const updateResponses = (reactive, response) => {
    const newState = responseSurvey.map(item => {      
      if (item.reactive === reactive) {
        return {...item, response};
      }
      // 👇️ otherwise return the object as is
      return item;
    });

    setResponseSurvey(newState);
  };

	//<Route path="/valorame/:hash/:nombre/:unidad/:fecha" element={<Survey />} />	
	const { hash, nombre, unidad, fecha } = useParams();

	useEffect(() => {
		setLoading(true); 

		const doExecute = async () => {
			try {
				const response = await axios.get(BASE_URL + `/survey/${hash}`, {
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					withCredentials: true,
				});
				setSurvey(response?.data);
				//console.log(response.data);
				setLoading(false);
			} catch (err) {
				if (!err?.response) {
					console.log(err.response);
				}
				setSurvey({});
			}
		};

		doExecute();		
		setSurvey({
			hash,
			nombre,
			unidad,
			fecha,
			level
		});
		
		return () => {			
			setLoading(false);
	};		
	}, []);

	if (loading) 
		return <Spinner />
	
	else if (!loading) {
    if (survey?.survey === 2) 
			return(
				<SurveyAccessDenied/>
			)
		

    if (survey?.survey === 1) 
			return(
				<SurveyEnd/>
			)
			
		else if (survey?.survey === 0 ) {
			if (level === 0) {		
				return (<Pane display="flex" flexWrap="nowrap" justifyContent="center">								
					<SurveyBegin 
						setLevel={setLevel}
						survey={survey}>
					</SurveyBegin>					
				</Pane>)			
			} 
			else if (level === 1) {
				return(
					<Survey01
						setLevel={setLevel}
						responseSurvey={responseSurvey}
						setResponseSurvey={setResponseSurvey}
						updateResponses={updateResponses}
					>					
					</Survey01>
				) 
			}						
			else if (level === 2) {
				return(
					<Survey02
						setLevel={setLevel}
						responseSurvey={responseSurvey}
						setResponseSurvey={setResponseSurvey}			
						updateResponses={updateResponses}
					>
					</Survey02>
				)
			}
			else if (level === 3) {
				return(
					<Survey03
						setLevel={setLevel}
						responseSurvey={responseSurvey}
						setResponseSurvey={setResponseSurvey}	
						updateResponses={updateResponses}
					>
					</Survey03>
				)
			}
			else if (level === 4) { // Final
				return(
					<SurveyFinishSuccess
						hash={hash}
						responseSurvey={responseSurvey}
					>
					</SurveyFinishSuccess>
				)
			}
			else if (level === 10) {
				return(
					<SurveyEnd></SurveyEnd>
				)
			}
		}
	}
}	

	

