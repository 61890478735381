import styles from "./css/ExpedienteCard.module.css";

export default function ViewPDFTab({ expediente }) {	

	const handleClick = () => {
		// decode base64 string, remove space for IE compatibility
		var binary = atob(expediente.informe_base64.replace(/\s/g, ""));
		var len = binary.length;
		var buffer = new ArrayBuffer(len);
		var view = new Uint8Array(buffer);

		for (var i = 0; i < len; i++) {
			view[i] = binary.charCodeAt(i);
		}

		var blob = new Blob([view], { type: "application/pdf" });
		window.open(URL.createObjectURL(blob), '_blank', 'noreferrer');
	};

	return (
		<>
			<button className={styles.buttonCard} onClick={event => handleClick(event)}>
				Ver resultados
			</button>
		</>
	);
}
