import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axios, { BASE_URL } from "./../api/axios";

import ReCAPTCHA from "react-google-recaptcha";


import styles from "./css/ExpedientePage.module.css";
import stylesForm from "./css/LoginPage.module.css";

export default function CustomRecaptcha() {
	const captchaRef = useRef(null);
	const { uuid } = useParams();
  const [activeButton, setActiveButton] = useState(false)
  const navigate = useNavigate();

  const handleRecaptcha =  async (event) => {
    event.preventDefault();
    const token = captchaRef.current.getValue();
    //captchaRef.current.reset();

    await axios.post(BASE_URL + `/recaptcha/${token}/${uuid}`)
        .then((res) =>  {
          navigate(`/exp/${res.data.hash_portal}/2`, { replace: true }) //Coloco 2 porque creo que ese corresponde a SMS, verificar
        }) 
        .catch((error) => {
          navigate("/")
        })
    }

  
  const captchaValidate = (value) => {
    setActiveButton(!value);
    setcaptcha(activeButton);
  }

  const [captcha, setcaptcha] = useState(false)

	return (
		<div className={styles.contentMain}>	
      { (!captcha) &&
        <div className={styles.formInput}>
					Para acceder indique que no es un robot.
				</div>				
      }
			<ReCAPTCHA 
          //sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          sitekey="6LdLsnYkAAAAAIrIEn6WE_JSyJIYibikFj8ROwXA"
          onChange={()=>captchaValidate()}
          ref={captchaRef}>        
			</ReCAPTCHA>
      
      <button className={stylesForm.formButtonHome}
        disabled={!activeButton}
        onClick={(event)=>handleRecaptcha(event)}>
        Acceder al expediente
      </button>
		</div>
	);
}
