//import { FaSpinner } from "react-icons/fa";

//import styles from "./css/Spinner.module.css";
import "./css/spinner.css";

export function Spinner() {
  return (
    <div className="spinner">
      <div className="loading-spinner">
      </div>    
    </div>
  );
}
