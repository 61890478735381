import axios from 'axios';


export const BASE_URL = 'https://unilabor-app.com:5050/api'; // production domain digitalOcean
//export const BASE_URL = 'http://192.168.1.125:5050/api';
//export const BASE_URL = 'http://127.0.0.1:5050/api';
 
export default axios.create({    
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Methods' : 'POST, GET, OPTIONS, DELETE, PUT'},
    withCredentials: true,
    rejectUnauthorized: false
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
