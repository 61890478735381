import React, { useEffect, useState } from "react";

import axios, { BASE_URL } from "./../api/axios";

import { Pane } from "evergreen-ui";

import { Spinner } from "../components/Spinner";
import EspecialidadCard from "../components/EspecialidadCard";

export default function CatEspecialidad() {
	const [loading, setloading] = useState(true);
	const [listado, setlistado] = useState([]);

	useEffect(() => {
		setloading(true);

		console.log("demo")
		const doExecute = async () => {
			try {
				const response = await axios.get(BASE_URL + "/medic/", {
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					withCredentials: true,
				});

				setlistado(response?.data);
				setloading(false);
			} catch (err) {
				if (!err?.response) {
					console.log(err.response);
				}
				setlistado([]);
			}
		};

		doExecute();
	}, []);

	if (loading) {
		return <Spinner />;
	}

	return (
		<>
			<Pane 				
				display="flex" 
				flexWrap="wrap" 
				justifyContent="center">
				{listado &&
					listado.map((item, idx) => (
						<EspecialidadCard key={idx} especialidad={item} />
					))}
			</Pane>
		</>
	);
}
