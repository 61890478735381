
import styles from "./css/ExpedienteCard.module.css";


export default function LeyendaInstitucion() {

  return (
    <>
      <div className={styles.contentLeyenda}>
        <p className={styles.contentLeyendaText}>Por motivos de seguridad, la información requerida solo puede ser obtenida por la institución a la que usted pertenece.</p>        
      </div>
    </>
  )
}