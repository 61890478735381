import React from "react";
import logo from './../assets/logo-white.png';
import styles from './css/Navbar.module.css';

function Navbar() {


	return (
		<div className="nav-main navbar-content">			
			<a href="https://unilabor.mx">
				<img className={styles.logoNavBar} src={logo} alt="Logo"></img>
			</a>

		</div>

	);
}

export default Navbar;
